import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { blue, green, grey, orange } from '@material-ui/core/colors'
import { Chip } from '@material-ui/core'

//import ConstantIcon from './ConstantIcon'
import FunctionIcon from './FunctionIcon'
import NumberIcon from './NumberIcon'
import VariableIcon from './VariableIcon'
import { TermTokenType } from 'treecalc'

const useStyles = makeStyles((_theme: Theme) =>
    createStyles({
        bracket: {
            color: grey[500],
        },
        constant: {
            color: grey[500],
        },
        default: {
            color: grey[500],
        },
        function: {
            color: blue[500],
        },
        number: {
            color: grey[900],
        },
        numberIcon: {
            color: grey[200],
        },
        operator: {
            color: orange[500],
        },
        variable: {
            color: green[500],
        }
    }),
)

export default function TokenChip(props: any) {
    const classes = useStyles()

    let icon: JSX.Element | undefined
    let className = classes.default
    switch (props.type) {
        case TermTokenType.Function:
            className = classes.function
            icon = <FunctionIcon className={className} />
            break
        case TermTokenType.Number:
            className = classes.number
            icon = <NumberIcon className={classes.numberIcon} />
            break
        case TermTokenType.Operator:
            className = classes.operator
            icon = undefined
            break
        case TermTokenType.Variable:
            className = classes.variable
            icon = <VariableIcon className={className} />
            break
        default:
            icon = undefined
    }

    return (

        <Chip className={className} label={props.string} icon={icon} variant="outlined" />

    )
}