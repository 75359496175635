import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export default function CalcTreeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <path d="m 39.770575,3.9679484 c -12.786688,0 -23.016016,10.2293466 -23.016016,23.0160166 0,3.119952 0.664988,6.188753 1.892509,9.052976 -4.449754,4.347452 -7.007094,10.280477 -7.007094,16.52038 0,12.78667 10.229347,23.016016 23.016015,23.016016 2.557341,0 5.114683,-0.562532 7.672006,-1.432042 V 96.03207 H 52.557342 V 69.282341 c 2.557341,0.716022 5.114663,1.176294 7.672004,1.176294 A 28.130685,28.130685 0 0 0 88.360026,42.327956 28.130685,28.130685 0 0 0 60.229346,14.197277 c -0.460272,0 -0.86951,0 -1.329782,0 C 54.654391,7.8550723 47.442716,3.9679296 39.770711,3.9679296 m 0,10.2293474 c 6.751364,0 12.326338,5.268113 12.786668,12.019477 C 54.910122,25.04046 57.672044,24.4267 60.229385,24.4267 A 17.901346,17.901346 0 0 1 78.130737,42.328053 17.901346,17.901346 0 0 1 60.229385,60.229404 c -4.910083,0 -9.564436,-1.994731 -12.991269,-5.574993 -1.022999,6.188753 -6.342184,10.689656 -12.582088,10.689656 A 12.786675,12.786675 0 0 1 21.869358,52.557399 c 0,-7.058244 4.091743,-10.024766 10.229348,-13.860759 -4.091743,-5.268114 -5.114683,-8.336917 -5.114683,-11.712598 A 12.786675,12.786675 0 0 1 39.770711,14.197373 Z" strokeWidth={5.11467} />

      <path d="m 33.98381,23.235843 h 17.529003 a 3.5058011,3.5058011 0 0 1 3.50577,3.505804 v 28.046407 a 3.5058011,3.5058011 0 0 1 -3.50577,3.505797 H 33.98381 A 3.5058011,3.5058011 0 0 1 30.478006,54.788054 V 26.741647 a 3.5058011,3.5058011 0 0 1 3.505804,-3.505804 m 0,3.505804 v 7.011599 H 51.512813 V 26.741647 H 33.98381 m 0,10.517403 v 3.505797 h 3.505796 V 37.25905 H 33.98381 m 7.011599,0 v 3.505797 h 3.505804 V 37.25905 h -3.505804 m 7.011601,0 v 3.505797 h 3.505803 V 37.25905 H 48.00701 m -14.0232,7.0116 v 3.505797 h 3.505796 V 44.27065 H 33.98381 m 7.011599,0 v 3.505797 h 3.505804 V 44.27065 h -3.505804 m 7.011601,0 v 3.505797 h 3.505803 V 44.27065 H 48.00701 m -14.0232,7.011601 v 3.505803 h 3.505796 V 51.282251 H 33.98381 m 7.011599,0 v 3.505803 h 3.505804 v -3.505803 h -3.505804 m 7.011601,0 v 3.505803 h 3.505803 v -3.505803 z" strokeWidth={1.7529} />
    </SvgIcon>
  )
}
