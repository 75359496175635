import { IconButton, InputAdornment, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
// import InfoIcon from '@material-ui/icons/InfoOutlined'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import { Alert, AlertTitle, TreeItem, TreeView } from '@material-ui/lab'
import React from 'react'
import { Logger, NamedValues, TermToken, TermTokenListClean, TermTokens, TermTokenTreeNode, TermTokenTrees } from 'treecalc'
import IndexOuterGrid from '../components/IndexOuterGrid'
import MainContent from '../components/MainContent'
import MetaTags from '../components/MetaTags'
import MyAppBar from '../components/MyAppBar'
import MyRoot from '../components/MyRoot'
import TokenChip from '../components/TokenChip'
import CalcTreeIcon from '../components/CalcTreeIcon'






// see: https://material-ui.com/components/tree-view/#RecursiveTreeView.tsx
let treeObject: any = null


const updateAnsVariable = function (newValue: number): boolean {
  return NamedValues.setVariableValue('ans', newValue)
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    'calcTreeIcon': {
      width: '100%',
      height: '100%',
      color: '#ccc',
      'max-height': '500px',
      'box-sizing': 'border-box',
    },
    'form': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    'result': {
      fontWeight: 700,
    },
    'alert': {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    'tokens': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    'grid': {
      margin: theme.spacing(2),
    },
    'treeView': {
      //height: 110,
      flexGrow: 1,
      //maxWidth: 400,
    },
    'treeAsString': {
      marginTop: theme.spacing(2),
      fontWeight: 200,
    },
  }),
)

export default function App() {
  const classes = useStyles()

  const [termString, setTermString] = React.useState('')

  const [errorMessage, setErrorMessage] = React.useState('')

  const [termTokens, setTermTokens] = React.useState([])

  const [treeResult, setTreeResult] = React.useState(0)

  const [treeAsString, setTreeAsString] = React.useState('')

  const [expanded, setExpanded] = React.useState<string[]>([])

  const handleToggle = (_event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleClickClearTermString = () => {
    setTermString('')
  }

  //let errorMessage: string = 'Noupelou!'

  const handleTermStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let termString: string = event.target.value
    setTermString(termString)
    setErrorMessage('')
    setTermTokens([])
    setTreeResult(0)
    Logger.setLogLevel('error')

    let parsed: TermToken[]
    try {
      parsed = TermTokens.parseStringToTermTokenList(termString)
    } catch (e) {
      setErrorMessage('Error parsing input to tokens')
      return
    }

    let cleaned: TermToken[]
    try {
      cleaned = TermTokenListClean.addMultiplyOperators(TermTokenListClean.addMissingTermTokenListBrackets(parsed))
      setTermTokens(TermTokens.getTermTokenListAsJsonObjectArray(cleaned) as never[])
    } catch (e) {
      setErrorMessage('Error adding multiply operators')
      return
    }

    let tree: TermTokenTreeNode | null
    try {
      tree = TermTokenTrees.parseTermTokenListToTermTokenTree(cleaned)

      if (!tree) {
        setErrorMessage('Tree is empty')
        return
      }

      treeObject = tree.getJsonObject()
      setExpanded(getAllTokenIdsWithChildren(treeObject))

    } catch (e) {
      setErrorMessage('Error creating tree: ' + e.message)
      return
    }

    try {
      let ans = tree.getValue()
      setTreeResult(ans)
      updateAnsVariable(ans)

    } catch (e) {
      setErrorMessage('Error evaluating tree: ' + e.message)
      return
    }

    try {
      setTreeAsString(tree.toString())
    } catch (e) {
      setErrorMessage('Error converting tree to string: ' + e.message)
      return
    }

    // setErrorMessage((termString.length % 2 == 0) ? 'Random Error Hui' : '')
  }

  const renderTree = (node: any) => (
    <TreeItem key={node.token.id} nodeId={node.token.id} label={node.token.string}>
      {Array.isArray(node.children) ? node.children.map((n: any) => renderTree(n)) : null}
    </TreeItem>
  )

  const getAllTokenIdsWithChildren = (node: any) => {
    let ret: string[] = []
    if (!node) {
      return ret
    }
    if (!node.children || !node.children.length) {
      return ret
    }
    ret.push(node.token.id)
    for (let c of node.children) {
      ret = ret.concat(getAllTokenIdsWithChildren(c))
    }
    return ret
  }

  return (
    <MyRoot>

      <MetaTags
        title="calctree.de"
        description="Calculate terms and display the calculation tree"
        lang="en"
      />

      <MyAppBar lang="en" />

      <MainContent>

        <IndexOuterGrid>
          <FormControl fullWidth className={classes.form} variant="outlined">
            <TextField
              id="term-string"
              label="What would you like to calculate today?"
              variant="outlined"
              color="primary"
              value={termString}
              onChange={handleTermStringChange}
              InputProps={(termString.length) ? {
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="clear term string"
                    onClick={handleClickClearTermString}
                    //onMouseDown={handleMouseDownClearTermString}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>,
              } : undefined}
            />
          </FormControl>

          {(() => {
            if (!termString.length) {
              return <CalcTreeIcon className={classes.calcTreeIcon} />
            }
          })()}

          {(() => {
            if (termString.length && errorMessage.length) {
              return <Alert severity="error" className={classes.alert} variant="outlined">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            }
          })()}

          {(() => {
            if (termString.length && !errorMessage.length) {
              return <Alert severity="success" className={classes.alert} variant="outlined">
                <AlertTitle className={classes.result}>
                  Result
                </AlertTitle>

                <Typography variant="body1" component="p" className={classes.result} gutterBottom>
                  {treeResult}
                </Typography>
              </Alert>
            }
          })()}

          {(() => {
            if (termString.length && !errorMessage.length && termTokens.length) {
              return <Alert severity="warning" className={classes.alert} variant="outlined" icon={<LabelOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Tokens</AlertTitle>
                <div className={classes.tokens}>
                  {termTokens.map((t: any, idx: any) => (
                    <TokenChip key={idx} string={t.string} type={t.type} />
                  ))}
                </div>
              </Alert>
            }
          })()}

          {(() => {
            if (termString.length && !errorMessage.length && termTokens.length && treeObject != null) {
              return <Alert severity="info" className={classes.alert} variant="outlined" icon={<AccountTreeOutlinedIcon fontSize="inherit" />}>
                <AlertTitle>Tree</AlertTitle>
                <TreeView
                  className={classes.treeView}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}

                  defaultExpanded={getAllTokenIdsWithChildren(treeObject)}
                  expanded={expanded}
                  onNodeToggle={handleToggle}
                >
                  {renderTree(treeObject)}
                </TreeView>
                <Typography variant="body2" component="p" className={classes.treeAsString} gutterBottom>
                  {treeAsString ? `Tree as Term String: ${treeAsString}` : ''}
                </Typography>
              </Alert>
            }
          })()}

        </IndexOuterGrid>

      </MainContent>

    </MyRoot >
  )
}
