import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

// @see: https://materialdesignicons.com/icon/function

export default function NumberIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
    </SvgIcon>
  )
}
